//for table header
.rdt_TableHeadRow {
    height: 3rem;
    font-size: 16px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

//for each individual cell
.rdt_TableCell {
    font-size: 14px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 100 !important;
}